import logo from './logo.svg';
import logo2 from './logo2.svg';
import thumbnail from './thumbnail.png';

import FinanceIcon from './Dynamics Apps/Finance.png';
import BCIcon from './Dynamics Apps/Business Management.svg';
import CIIcon from './Dynamics Apps/Customer Insights.png';
import CSIcon from './Dynamics Apps/Customer Service.png';
import FSIcon from './Dynamics Apps/Field Services.png';
import POIcon from './Dynamics Apps/Project Operations.png';
import SalesIcon from './Dynamics Apps/sales.svg';
import SCIcon from './Dynamics Apps/Supply Chain.png';
import partners from './partners.jpg';

import AzureThumbnail from './devops.png';
import FoldersThumbnail from './folders.webp';
import SimplifyThumbnail from './simplify.webp';
import TemplatesThumbnail from './templates.jpg';
import ComponentsThumbnail from './components.jpg';
import ScenarioThumbnail from './scenario.jpg';

// Videos
// import MainVideo from "./videos/Main.mp4";
// import RSATVideo from "./videos/RSAT.mp4";
// import ReportVideo from "./videos/Reporting.mp4";
// import VariablesVideo from "./videos/Variables.mp4";

import './App.css';
import { useState } from 'react';

const MainVideo = "https://www.youtube.com/embed/sKDox6AJL1k?si=Vs5i_njaguXFBkqS";
const RSATVideo = "https://www.youtube.com/embed/Z-3V7r1HS5w?si=peOTb72dNOCqthJx";
const ReportVideo = "https://www.youtube.com/embed/YjHqYNQ1nJw?si=18rIgBzsEPZrwHuo";
const VariablesVideo = "https://www.youtube.com/embed/97NtEve_oXg?si=hHBUFftRQMROSIrE";

function App() {
  const [nav, setNav] = useState(false);

  const MenuTwoLineIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={50} height={50} color={nav ? "#EFA7D7" : "#ffffff"} fill={"none"} {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M3 8L21 8L21 10L3 10L3 8Z" fill="currentColor" />
      <path fillRule="evenodd" clipRule="evenodd" d="M3 14L15 14L15 16L3 16L3 14Z" fill="currentColor" />
    </svg>
  );

  return (
    <div className="App">

      <div id="backgroundBlur" onClick={() => setNav(!nav)} style={{display: nav ? "inline-block" : "none"}}></div>
      <nav className="sideNav" style={{display: nav ? "inline-block" : "none"}}>
        <div className="logoWrap">
          <MenuTwoLineIcon onClick={() => setNav(!nav)} />
          <img src={logo2} />
        </div>
        <button className="tab">Testing</button>
        <ul onClick={e => {if (e.target.tagName === "A") setNav(!nav);}}>
          <li><a href="#introduction">Introduction</a></li>
          <li><a href="#coverage">Coverage</a></li>
          <li><a href="#standoutFeatures">Why Choose Us?</a>
            <ul>
              <li><a href="#page-rsat">RSAT Conversion</a></li>
              <li><a href="#page-devops">Azure DevOps Integration</a></li>
              <li><a href="#page-templates">Standard Templates</a></li>
              <li><a href="#page-providers">Service Providers</a></li>
            </ul>
          </li>
          <li><a href="#coreFeatures">Core Features</a>
            <ul>
              <li><a href="#page-components">Components</a></li>
              <li><a href="#page-variables">Variables</a></li>
              <li><a href="#page-scenarios">Scenarios</a></li>
              <li><a href="#page-reporting">Reporting</a></li>
              <li><a href="#page-organise">Organise Tests Your Way</a></li>
              <li><a href="#page-development">Simplify Test Creation</a></li>
            </ul>
          </li>
        </ul>
      </nav>

      <header className="App-header">
        <MenuTwoLineIcon onClick={() => setNav(!nav)} />
        <img id="logo" src={logo} />
        <div></div>
      </header>

      <section className="titleWrap">
        <h1 className="heading1">We're revolutionising test automation for D365.</h1>
      </section>

      <section className="videoWrap" id="introduction">
        <iframe
          className='video1'
          src={MainVideo}
          title="Main Video"
          frameborder="0"
          allowFullScreen
        />
      </section>

      <section id="coverage">
  <h3>What do we cover?</h3>
  <h2>Everything you need for seamless, web-based test automation with a focus on D365.</h2>
  <div id="app-list">
    <img src={FinanceIcon} alt="Finance Icon" title="Finance" />
    <img src={BCIcon} alt="Business Management Icon" title="Business Management" />
    <img src={CIIcon} alt="Customer Insights Icon" title="Customer Insights" />
    <img src={CSIcon} alt="Customer Service Icon" title="Customer Service" />
    <img src={FSIcon} alt="Field Services Icon" title="Field Services" />
    <img src={POIcon} alt="Project Operations Icon" title="Project Operations" />
    <img src={SalesIcon} alt="Sales Icon" title="Sales" />
    <img src={SCIcon} alt="Supply Chain Icon" title="Supply Chain" />
  </div>
</section>

      <section id="standoutFeatures">
        <h3>Why choose Elevaite365?</h3>
        <h2>Discover the features that set us apart and help you save time, reduce costs, and maximise efficiency.</h2>
        <hr />

        <section id="page-rsat" className="featureSection">
          <aside className="featureContent">
            <h3>Instantly Convert RSAT Tests</h3>
            <p>Transitioning from F&O's RSAT tool? Drag and drop your test cases into Elevaite365 and convert them instantly, saving hours of manual effort.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail">
              <iframe
                className='tinyVideo'
                src={RSATVideo}
                title="RSAT Video"
                frameborder="0"
                allowFullScreen
              />
            </div>
          </aside>
        </section>

        <section id="page-devops" className="featureSection">
          <aside className="featureContent">
            <h3>Seamless Azure DevOps Integration</h3>
            <p>Connect your test library directly to Azure DevOps. Automate test execution and access comprehensive reporting, all within your workflow.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail" style={{backgroundImage: `URL(${AzureThumbnail})`}}></div>
          </aside>
        </section>

        <section id="page-templates" className="featureSection">
          <aside className="featureContent">
            <h3>Jumpstart with Pre-Built Templates</h3>
            <p>Leverage our growing library of templates created in collaboration with global partners. Build extensive test coverage faster than ever before.</p>
          </aside>
          <aside className="featureContent">
          <div className="thumbnail" style={{backgroundImage: `URL(${TemplatesThumbnail})`}}></div>
          </aside>
        </section>

        <section id="page-providers" className="featureSection">
          <aside className="featureContent">
            <h3>Expert Support When You Need It</h3>
            <p>Don’t have time to manage testing in-house? Our network of expert service providers can handle test creation, execution, and maintenance for you.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail" style={{backgroundImage: `URL(${partners})`}}></div>
          </aside>
        </section>

      </section>

      <section id="coreFeatures">
        <h3>A Complete Testing Solution</h3>
        <h2>From test organisation to in-depth reporting, Elevaite365 has everything you need for streamlined automation.</h2>
        <hr />

        <section id="page-components" className="featureSection">
          <aside className="featureContent">
            <h3>Reuse and Save Time</h3>
            <p>Stop duplicating work. Create reusable components to standardise and streamline your testing workflows.</p>
          </aside>
          <aside className="featureContent">
          <div className="thumbnail" style={{backgroundImage: `URL(${ComponentsThumbnail})`}}></div>
          </aside>
        </section>

        <section id="page-variables" className="featureSection">
          <aside className="featureContent">
            <h3>Streamlined Test Data Management</h3>
            <p>Easily manage test data by saving values, such as a sales order, during one test and reusing them in others. This powerful feature is ideal for creating dynamic test scenarios and is especially useful for integration testing and ensuring seamless data synchronisation between applications.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail">
              <iframe
                className='tinyVideo'
                src={VariablesVideo}
                title="Variables Video"
                frameborder="0"
                allowFullScreen
              />
            </div>
          </aside>
        </section>

        <section id="page-scenarios" className="featureSection">
          <aside className="featureContent">
            <h3>Effortless Multi-Test Execution with Scenarios</h3>
            <p>Run multiple tests with one click or schedule overnight test runs to maximise productivity.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail" style={{backgroundImage: `URL(${ScenarioThumbnail})`}}></div>
          </aside>
        </section>

        <section id="page-reporting" className="featureSection">
          <aside className="featureContent">
            <h3>Insightful Reporting, Instantly</h3>
            <p>Get AI-powered error insights, replayable test videos, and detailed reports to debug faster and make better decisions.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail">
              <iframe
                className='tinyVideo'
                src={ReportVideo}
                title="Report Video"
                frameborder="0"
                allowFullScreen
              />
            </div>
          </aside>
        </section>

        <section id="page-organise" className="featureSection">
          <aside className="featureContent">
            <h3>Organise Tests Your Way</h3>
            <p>Take control with custom naming systems, folder structures, and run schedules designed to fit your team's needs.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail" style={{backgroundImage: `URL(${FoldersThumbnail})`}}></div>
          </aside>
        </section>

        <section id="page-development" className="featureSection">
          <aside className="featureContent">
            <h3>Simplify Test Creation</h3>
            <p>Our intuitive interface turns complex protocols into an easy-to-navigate experience, making test automation effortless.</p>
          </aside>
          <aside className="featureContent">
            <div className="thumbnail" style={{backgroundImage: `URL(${SimplifyThumbnail})`}}></div>
          </aside>
        </section>

      </section>
    </div>
  );
}

export default App;
